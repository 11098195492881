body {
  color: #444;
  font-family: Open Sans, sans-serif;
}

a {
  color: #77d518;
  text-decoration: none;
}

a:hover {
  color: #ffce3a;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Raleway, sans-serif;
}

.back-to-top {
  visibility: hidden;
  opacity: 0;
  z-index: 996;
  width: 40px;
  height: 40px;
  background: #77d518;
  border-radius: 50px;
  transition: all .4s;
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.back-to-top i {
  color: #fff;
  font-size: 28px;
  line-height: 0;
}

.back-to-top:hover {
  color: #fff;
  background: #ffcb30;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

#header {
  z-index: 997;
  background: #374055e6;
  padding: 20px 0;
  transition: all .5s;
}

#header.header-transparent {
  background: none;
}

#header.header-scrolled {
  background: #374055e6;
  padding: 15px 0;
}

#header .logo {
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 1;
}

#header .logo a {
  color: #fff;
}

#header .logo img {
  max-height: 28px;
}

.navbar {
  padding: 0;
}

.navbar ul {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.navbar li {
  position: relative;
}

.navbar a, .navbar a:focus {
  color: #fff;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 30px;
  font-size: 14px;
  transition: all .3s;
  display: flex;
}

.navbar a i, .navbar a:focus i {
  margin-left: 5px;
  font-size: 12px;
  line-height: 0;
}

.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
  color: #77d518;
}

.navbar .dropdown ul {
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  margin: 0;
  padding: 10px 0;
  transition: all .3s;
  display: block;
  position: absolute;
  top: calc(100% + 30px);
  left: 14px;
  box-shadow: 0 0 30px #7f89a140;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  text-transform: none;
  color: #414c64;
  padding: 10px 20px;
  font-size: 14px;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
  color: #77d518;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.navbar .dropdown .dropdown ul {
  visibility: hidden;
  top: 0;
  left: calc(100% - 30px);
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 0;
  left: 100%;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

.mobile-nav-toggle {
  color: #fff;
  cursor: pointer;
  font-size: 28px;
  line-height: 0;
  transition: all .5s;
  display: none;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  z-index: 999;
  background: #2d3545e6;
  transition: all .3s;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  background-color: #fff;
  padding: 10px 0;
  transition: all .3s;
  display: block;
  position: absolute;
  inset: 55px 15px 15px;
  overflow-y: auto;
}

.navbar-mobile a, .navbar-mobile a:focus {
  color: #414c64;
  padding: 10px 20px;
  font-size: 15px;
}

.navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
  color: #77d518;
}

.navbar-mobile .dropdown ul {
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  margin: 10px 20px;
  padding: 10px 0;
  display: none;
  position: static;
  box-shadow: 0 0 30px #7f89a140;
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
  color: #77d518;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

#hero {
  width: 100%;
  height: 80vh;
  background: url("hero-bg.09abdb8c.jpg") top / cover;
  position: relative;
}

#hero:before {
  content: "";
  background: #2d3545b3;
  position: absolute;
  inset: 0;
}

#hero .container {
  text-align: center;
  padding-top: 50px;
}

@media (max-width: 992px) {
  #hero .container {
    padding-top: 0;
  }
}

#hero h1 {
  color: #fff;
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
}

#hero h2 {
  color: #eee;
  margin: 15px 0 0;
  font-size: 22px;
}

#hero .btn-get-started {
  letter-spacing: 1px;
  color: #fff;
  border: 2px solid #77d518;
  border-radius: 50px;
  margin-top: 40px;
  padding: 9px 35px;
  font-family: Raleway, sans-serif;
  font-size: 15px;
  font-weight: 500;
  transition: all .5s;
  display: inline-block;
}

#hero .btn-get-started:hover {
  background: #77d518;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero {
    height: 100vh;
  }

  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
  }
}

section {
  padding: 60px 0;
}

.section-bg {
  background-color: #f7f8fa;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-size: 32px;
  font-weight: bold;
  position: relative;
}

.section-title h2:after {
  content: "";
  width: 50px;
  height: 3px;
  background: #77d518;
  display: block;
  position: absolute;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  color: #aab3c7;
  margin-bottom: 0;
  font-size: 14px;
}

.breadcrumbs {
  min-height: 40px;
  background-color: #fafafc;
  margin-top: 82px;
  padding: 20px 0;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 68px;
  }
}

.breadcrumbs h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 300;
}

@media (max-width: 992px) {
  .breadcrumbs h2 {
    margin: 0 0 10px;
  }
}

.breadcrumbs ol {
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  font-size: 14px;
  list-style: none;
  display: flex;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li:before {
  color: #6c757d;
  content: "/";
  padding-right: 10px;
  display: inline-block;
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

.clients {
  text-align: center;
  padding: 15px 0;
}

.clients img {
  max-width: 45%;
  filter: grayscale(100);
  padding: 15px 0;
  transition: all .4s ease-in-out;
  display: inline-block;
}

.clients img:hover {
  filter: none;
  transform: scale(1.15);
}

@media (max-width: 768px) {
  .clients img {
    max-width: 40%;
  }
}

.about h3 {
  color: #414c64;
  font-size: 34px;
  font-weight: 700;
}

.about h4 {
  margin-top: 5px;
  font-size: 20px;
  font-weight: 700;
}

.about i {
  color: #8b97b3;
  margin-top: 15px;
  font-size: 48px;
}

.about p {
  color: #848484;
  font-size: 15px;
}

.counts {
  padding: 40px 0;
}

.counts .counters span {
  color: #414c64;
  font-size: 48px;
  display: block;
}

.counts .counters p {
  margin: 0 0 20px;
  padding: 0;
  font-family: Raleway, sans-serif;
  font-size: 14px;
}

.services .icon-box {
  text-align: center;
  background: #fff;
  padding: 70px 20px 80px;
  transition: all .3s ease-in-out;
  box-shadow: 0 5px 90px #6e7b830d;
}

.services .icon-box .icon {
  width: 64px;
  height: 64px;
  background: #f5f5f5;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  transition: all .3s ease-in-out;
  display: flex;
  position: relative;
}

.services .icon-box .icon i {
  font-size: 32px;
  line-height: 1;
  transition: all .5s;
  position: relative;
}

.services .icon-box h4 {
  margin: 10px 0 15px;
  font-size: 22px;
  font-weight: 600;
}

.services .icon-box h4 a {
  color: #414c64;
  transition: all .3s ease-in-out;
}

.services .icon-box p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 24px;
}

.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0 0 35px #00000014;
}

.services .iconbox-blue i {
  color: #47aeff;
}

.services .iconbox-blue:hover .icon {
  background: #47aeff;
}

.services .iconbox-blue:hover .icon i {
  color: #fff;
}

.services .iconbox-orange i {
  color: #ffa76e;
}

.services .iconbox-orange:hover .icon {
  background: #ffa76e;
}

.services .iconbox-orange:hover .icon i {
  color: #fff;
}

.services .iconbox-pink i {
  color: #e80368;
}

.services .iconbox-pink:hover .icon {
  background: #e80368;
}

.services .iconbox-pink:hover .icon i {
  color: #fff;
}

.services .iconbox-yellow i {
  color: #ffbb2c;
}

.services .iconbox-yellow:hover .icon {
  background: #ffbb2c;
}

.services .iconbox-yellow:hover .icon i {
  color: #fff;
}

.services .iconbox-red i {
  color: #ff5828;
}

.services .iconbox-red:hover .icon {
  background: #ff5828;
}

.services .iconbox-red:hover .icon i {
  color: #fff;
}

.services .iconbox-teal i {
  color: #11dbcf;
}

.services .iconbox-teal:hover .icon {
  background: #11dbcf;
}

.services .iconbox-teal:hover .icon i {
  color: #fff;
}

.cta {
  background: #414c64 0 0 / cover;
  padding: 60px 0;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
}

.cta .cta-btn {
  letter-spacing: 1px;
  color: #fff;
  border: 2px solid #77d518;
  border-radius: 25px;
  margin-top: 10px;
  padding: 10px 35px;
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-weight: 500;
  transition: all .5s;
  display: inline-block;
}

.cta .cta-btn:hover {
  background: #77d518;
}

.testimonials .testimonials-carousel, .testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  min-height: 320px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  z-index: 2;
  border: 6px solid #fff;
  border-radius: 50%;
  margin: -40px 0 0 40px;
  position: relative;
}

.testimonials .testimonial-item h3 {
  color: #111;
  margin: 10px 0 5px 45px;
  font-size: 18px;
  font-weight: bold;
}

.testimonials .testimonial-item h4 {
  color: #999;
  margin: 0 0 0 45px;
  font-size: 14px;
}

.testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
  color: #77d518;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  position: relative;
  left: -5px;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  position: relative;
  top: 10px;
  right: -5px;
}

.testimonials .testimonial-item p {
  z-index: 1;
  background: #fafafc;
  border-radius: 6px;
  margin: 0 15px;
  padding: 20px 20px 60px;
  font-style: italic;
  position: relative;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: #fff;
  border: 1px solid #77d518;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #77d518;
}

.portfolio #portfolio-flters {
  text-align: center;
  border-radius: 50px;
  margin: 0 auto 25px;
  padding: 0;
  list-style: none;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  color: #444;
  background: #fafafc;
  border-radius: 50px;
  margin: 0 3px 10px;
  padding: 8px 18px 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  transition: all .3s ease-in-out;
  display: inline-block;
}

.portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
  color: #414c64;
  background: #77d518;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-item {
  margin-bottom: 30px;
  overflow: hidden;
}

.portfolio .portfolio-item img {
  transition: all .6s cubic-bezier(.645, .045, .355, 1);
  position: relative;
  top: 0;
}

.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  z-index: 3;
  background: #77d518;
  padding: 15px 20px;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: -50px;
  left: 15px;
  right: 15px;
}

.portfolio .portfolio-item .portfolio-info h4 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.portfolio .portfolio-item .portfolio-info p {
  color: #fff;
  margin-bottom: 0;
  font-size: 14px;
}

.portfolio .portfolio-item .portfolio-info .preview-link, .portfolio .portfolio-item .portfolio-info .details-link {
  color: #ffffffb3;
  font-size: 24px;
  transition: all .3s ease-in-out;
  position: absolute;
  top: calc(50% - 18px);
  right: 50px;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover, .portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: #fff;
}

.portfolio .portfolio-item .portfolio-info .details-link {
  right: 15px;
}

.portfolio .portfolio-item:hover img {
  top: -30px;
}

.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
  bottom: 0;
}

.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: #fff;
  border: 1px solid #77d518;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #77d518;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0 0 30px #414c6414;
}

.portfolio-details .portfolio-info h3 {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-size: 22px;
  font-weight: 700;
}

.portfolio-details .portfolio-info ul {
  padding: 0;
  font-size: 15px;
  list-style: none;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 700;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

.team .member {
  text-align: center;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: 0 2px 15px #414c640f;
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  height: 40px;
  opacity: 0;
  text-align: center;
  background: #ffffffd9;
  justify-content: center;
  align-items: center;
  transition: all .3s ease-in-out;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.team .member .social a {
  color: #414c64;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  transition: color .3s;
  display: inline-flex;
}

.team .member .social a i {
  line-height: 0;
}

.team .member .social a:hover {
  color: #77d518;
}

.team .member .social i {
  margin: 0 2px;
  font-size: 18px;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  color: #414c64;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 700;
}

.team .member .member-info span {
  color: #aaa;
  font-size: 13px;
  font-weight: 400;
  display: block;
}

.team .member .member-info p {
  color: #777;
  font-size: 14px;
  font-style: italic;
  line-height: 26px;
}

.team .member:hover .social {
  opacity: 1;
}

.pricing .box {
  text-align: center;
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 4px #0000001f;
}

.pricing h3 {
  color: #777;
  background: #f8f8f8;
  margin: -20px -20px 20px;
  padding: 20px 15px;
  font-size: 16px;
  font-weight: 600;
}

.pricing h4 {
  color: #77d518;
  margin-bottom: 20px;
  font-family: Poppins, sans-serif;
  font-size: 36px;
  font-weight: 600;
}

.pricing h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing ul {
  color: #444;
  text-align: center;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  list-style: none;
}

.pricing ul li {
  padding-bottom: 16px;
}

.pricing ul i {
  color: #77d518;
  padding-right: 4px;
  font-size: 18px;
}

.pricing ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .btn-wrap {
  text-align: center;
  background: #f8f8f8;
  margin: 20px -20px -20px;
  padding: 20px 15px;
}

.pricing .btn-buy {
  color: #6c7c9f;
  border: 2px solid #c8cedc;
  border-radius: 4px;
  padding: 10px 35px;
  font-family: Raleway, sans-serif;
  font-size: 14px;
  font-weight: 600;
  transition: all .3s;
  display: inline-block;
}

.pricing .btn-buy:hover {
  background: #fff;
}

.pricing .featured h3 {
  color: #fff;
  background: #77d518;
}

.pricing .featured .btn-buy {
  color: #fff;
  background: #77d518;
  border: 2px solid #77d518;
}

.pricing .featured .btn-buy:hover {
  background: #ffce3a;
}

.pricing .advanced {
  width: 200px;
  z-index: 1;
  color: #fff;
  background: #77d518;
  padding: 1px 0 3px;
  font-size: 14px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
}

.faq .faq-list {
  padding: 0 100px;
}

.faq .faq-list ul {
  padding: 0;
  list-style: none;
}

.faq .faq-list li + li {
  margin-top: 15px;
}

.faq .faq-list li {
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  position: relative;
}

.faq .faq-list a {
  cursor: pointer;
  outline: none;
  padding: 0 30px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: block;
  position: relative;
}

.faq .faq-list .icon-help {
  color: #77d518;
  font-size: 24px;
  position: absolute;
  left: 20px;
  right: 0;
}

.faq .faq-list .icon-show, .faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  top: 0;
  right: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list a.collapsed {
  color: #343a40;
  transition: all .3s;
}

.faq .faq-list a.collapsed:hover {
  color: #77d518;
}

.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}

.contact .info-box {
  color: #444;
  text-align: center;
  padding: 20px 0 30px;
  box-shadow: 0 0 20px #414c640d;
}

.contact .info-box i {
  color: #77d518;
  border: 2px dotted #ffeeba;
  border-radius: 50%;
  padding: 8px;
  font-size: 32px;
}

.contact .info-box h3 {
  color: #777;
  margin: 10px 0;
  font-size: 20px;
  font-weight: 700;
}

.contact .info-box p {
  margin-bottom: 0;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
}

.contact .php-email-form {
  padding: 30px;
  box-shadow: 0 0 20px #414c640d;
}

.contact .php-email-form .error-message {
  color: #fff;
  text-align: left;
  background: #ed3c0d;
  padding: 15px;
  font-weight: 600;
  display: none;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  color: #fff;
  text-align: center;
  background: #77d518;
  padding: 15px;
  font-weight: 600;
  display: none;
}

.contact .php-email-form .loading {
  text-align: center;
  background: #fff;
  padding: 15px;
  display: none;
}

.contact .php-email-form .loading:before {
  content: "";
  width: 24px;
  height: 24px;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  border-radius: 50%;
  margin: 0 10px -6px 0;
  animation: animate-loading 1s linear infinite;
  display: inline-block;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  box-shadow: none;
  border-radius: 4px;
  font-size: 14px;
}

.contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
  border-color: #77d518;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  color: #fff;
  background: #77d518;
  border: 0;
  border-radius: 50px;
  padding: 10px 30px;
  transition: all .4s;
}

.contact .php-email-form button[type="submit"]:disabled {
  color: #bbb;
  background: #777;
}

.contact .php-email-form button[type="submit"]:disabled:hover {
  color: #bbb;
  background: #888;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #ffce3a;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#footer {
  color: #414c64;
  background: #f4f5f8;
  font-size: 14px;
}

#footer .footer-top {
  background: #fafafc;
  padding: 60px 0 30px;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h4 {
  margin: 0 0 30px;
  padding: 2px 0;
  font-size: 22px;
  font-weight: 700;
  line-height: 1;
}

#footer .footer-top .footer-contact p {
  color: #777;
  margin-bottom: 0;
  font-family: Raleway, sans-serif;
  font-size: 14px;
  line-height: 24px;
}

#footer .footer-top h4 {
  color: #444;
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#footer .footer-top .footer-links ul i {
  color: #77d518;
  padding-right: 2px;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  align-items: center;
  padding: 10px 0;
  display: flex;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #777;
  line-height: 1;
  transition: all .3s;
  display: inline-block;
}

#footer .footer-top .footer-links ul a:hover {
  color: #77d518;
  text-decoration: none;
}

#footer .footer-newsletter {
  font-size: 15px;
}

#footer .footer-newsletter h4 {
  color: #444;
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}

#footer .footer-newsletter form {
  text-align: left;
  background: #fff;
  border: 1px solid #e1e4ec;
  border-radius: 50px;
  margin-top: 30px;
  padding: 6px 10px;
  position: relative;
}

#footer .footer-newsletter form input[type="email"] {
  width: calc(100% - 100px);
  border: 0;
  padding: 4px 8px;
}

#footer .footer-newsletter form input[type="submit"] {
  color: #fff;
  background: #77d518;
  border: 0;
  border-radius: 50px;
  padding: 0 20px;
  font-size: 16px;
  transition: all .3s;
  position: absolute;
  top: -1px;
  bottom: -1px;
  right: -1px;
  box-shadow: 0 2px 15px #0000001a;
}

#footer .footer-newsletter form input[type="submit"]:hover {
  background: #ffce3a;
}

#footer .credits {
  padding-top: 5px;
  font-size: 13px;
}

#footer .social-links a {
  color: #5f6f92;
  text-align: center;
  width: 36px;
  height: 36px;
  background: #e1e4ec;
  border-radius: 50%;
  margin-right: 4px;
  padding: 8px 0;
  font-size: 18px;
  line-height: 1;
  transition: all .3s;
  display: inline-block;
}

#footer .social-links a:hover {
  background: #ffce3a;
  text-decoration: none;
}

/*# sourceMappingURL=index.432d4af9.css.map */
